

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import KisiTipiPicker from "@/components/pickers/KisiTipiPicker.vue";
import KisiForm from "@/components/forms/KisiForm.vue";
import KurumForm from "@/components/forms/KurumForm.vue";

@Component({
  components: {KurumForm, KisiForm, KisiTipiPicker,}
})
export default class platform extends Mixins(ObjectInputMixin) {
  @Prop() searched!: boolean;
  @Prop() hideButton!: any;
  @Prop() existings!: Array<KisiEntity | KurumEntity>
  @Prop() muvekkilMi !: boolean;
  @Prop() isAddressEmpty !: boolean;

  searchItems: Array<KisiEntity | KurumEntity> = [];
  selectedItem: KisiEntity | KurumEntity = new KisiEntity() || new KurumEntity();
  searchLabel: string = "";
  searchText: string = "";

  @Watch('value')
  protected onValueChange() {
    this.localValue = this.value;
  }

  get sahisMi(): boolean {
    if (this.localValue.kisi_kurum_turu) {
      return this.localValue.kisi_kurum_turu == KisiKurumTuru.sahis;
    }
    return !!this.localValue.kisi;
  }

  get sirketMi(): boolean {
    if (this.localValue.kisi_kurum_turu) {
      return this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket;
    }
    return !!this.localValue.kurum;
  }

  get isHideButton() {
    return this.hideButton || this.hideButton === "";
  }

  vekiller(){
    this.$emit('vekiller')
  }

  mounted() {
    if (!this.value) {
      this.onKisiKurumTuruChange();
      this.searchItems = [];
      this.addNewItemOption();
    } else {
      this.input();
    }
  }

  @Watch('searchText')
  onSearchTextChange() {
    this.search();
  }

  timeoutId: any = null;
  minSearchLength = 1; // Minimum arama uzunluğu

  load() {
    this.$emit("load");
  }

  onKisiKurumTuruChange() {
    delete this.localValue.kisi;
    delete this.localValue.kurum;
    if (this.sahisMi) {
      this.localValue.kisi = new KisiEntity();
      this.searchLabel = "TC Kimlik No veya İsim";
    } else if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket) {
      this.localValue.kurum = new KurumEntity();
      this.searchLabel = "Vergi No, Sicil No, Mersis No veya İsim";
    }
    this.input();
  }

  onKisiKurumChange() {
    if (this.sahisMi) {
      this.localValue.kisi = this.selectedItem;

      if (this.localValue.kisi.id === 0) {
        this.localValue.kisi.ad = "";
        this.clearSearchForm();
      } else {
        const action = `/api/v1/kisi/${this.localValue.kisi.id}`;
        this.$http.get(action)
            .then((response) => {
              this.localValue.kisi = response.data.kisi;
              this.localValue.kisi.borcluVekilleri = response.data.borcluVekilleri || [];
              this.$emit('selected', response.data);
              this.clearSearchForm();
              this.input();
            })
            .catch(error => {
              console.error("Kişi bilgileri alınırken hata oluştu:", error);
            });
      }

    } else if (this.sirketMi) {
      this.localValue.kurum = this.selectedItem;

      if (this.localValue.kurum.id === 0) {
        this.localValue.kurum.sirket_adi = "";
        this.clearSearchForm();
      } else {
        const action = `/api/v1/kurum/${this.localValue.kurum.id}`;
        this.$http.get(action)
            .then((response) => {
              this.localValue.kurum = response.data;
              this.$emit('selected', response.data);
              this.clearSearchForm();
              this.input();
            })
            .catch(error => {
              console.error("Kurum bilgileri alınırken hata oluştu:", error);
            });
      }
    }
  }


  clearSearchForm() {
    this.$emit('change');
    this.selectedItem = new KisiEntity();
    this.searchText = "";
  }

  async search() {
    clearTimeout(this.timeoutId);
    const yeniKisiOption = {
      ...new KisiEntity(),
      ...new KurumEntity(),
      id: 0,
      ad: "Yeni Kişi",
      soyad: "",
      sirket_adi: "Yeni Kurum"
    };
    
    this.searchItems = [yeniKisiOption];
  
    if (!this.searchText || this.searchText === "null" || this.searchText.length < this.minSearchLength) {
      return;
    }
  
    this.timeoutId = setTimeout(() => {
      let action = "";
      if (this.sahisMi) {
        action = '/api/v1/kisi/filter?keyword=' + encodeURIComponent(this.searchText);
      } else if (this.sirketMi) {
        action = '/api/v1/kurum/filter?keyword=' + encodeURIComponent(this.searchText);
      }
      
      if (action !== "") {
        this.$http.get(action).then((response) => {

          let apiResults = [];
          if (response && response.data) {
            if (Array.isArray(response.data)) {
              console.log("Yanıt bir dizi olarak geldi");
              apiResults = response.data;
            } else if (response.data.items && Array.isArray(response.data.items)) {
              console.log("Yanıt 'items' içinde bir dizi olarak geldi");
              apiResults = response.data.items;
            } else if (response.data.data && Array.isArray(response.data.data)) {
              console.log("Yanıt 'data' içinde bir dizi olarak geldi");
              apiResults = response.data.data;
            } else if (typeof response.data === 'object' && Object.keys(response.data).length > 0) {
              console.log("Yanıt tekil bir nesne olarak geldi, diziye çevriliyor");
              apiResults = [response.data];
            }
          }
          
          console.log("İşlenen API Sonuçları:", apiResults);
          
          if (apiResults.length === 0) {
            console.log("API sonuçları boş, sadece 'Yeni Kişi/Kurum' seçeneği gösterilecek");
            this.searchItems = [yeniKisiOption];
            return;
          }
          
          const filteredResults = this.filterExistings(apiResults);
          const matchingResults = this.filterBySearchText(filteredResults);
          
          console.log("Filtrelenmiş Sonuçlar:", matchingResults.length);
          
          this.searchItems = [yeniKisiOption, ...matchingResults];
        }).catch((error) => {
          console.error("API Hatası:", error);
          this.searchItems = [yeniKisiOption];
        });
      }
    }, 200);
  }

  filterExistings(items: Array<KisiEntity | KurumEntity>) {
    if (!this.existings || !Array.isArray(this.existings)) {
      return items;
    }
    return items.filter((item: KisiEntity | KurumEntity) => {
      return this.existings.filter((ex: KisiEntity | KurumEntity) => {
        return ex.id === item.id;
      }).length === 0;
    });
  }

  filterBySearchText(items: Array<KisiEntity | KurumEntity>) {
    if (!this.searchText) {
      return items;
    }
    const searchLower = this.searchText.toLowerCase();
    return items.filter((item: any) => {
      try {
        if (this.sahisMi) {
          const ad = item.ad ? item.ad.toString().toLowerCase() : '';
          const soyad = item.soyad ? item.soyad.toString().toLowerCase() : '';
          const tcNo = item.tc_kimlik_no ? item.tc_kimlik_no.toString().toLowerCase() : '';
          return ad.includes(searchLower) || 
                 soyad.includes(searchLower) ||
                 tcNo.includes(searchLower);
        } else if (this.sirketMi) {
          const sirketAdi = item.sirket_adi ? item.sirket_adi.toString().toLowerCase() : '';
          const vergiNo = item.vergi_no ? item.vergi_no.toString().toLowerCase() : '';
          const mersisNo = item.mersis_no ? item.mersis_no.toString().toLowerCase() : '';
          return sirketAdi.includes(searchLower) || 
                 vergiNo.includes(searchLower) ||
                 mersisNo.includes(searchLower);
        }
        return false;
      } catch (error) {
        console.error("Filtreleme hatası:", error, "İlgili öğe:", item);
        return false;
      }
    });
  }

  addNewItemOption() {
    const newItem = {
      ...new KisiEntity(),
      ...new KurumEntity(),
      id: 0,
      ad: "Yeni Kişi",
      soyad: "",
      sirket_adi: "Yeni Kurum"
    };
    this.searchItems = [newItem, ...this.searchItems];
  }

  updateSearchText(newSearchText: string) {
    this.searchText = newSearchText;
  }

  forceMenuOpen() {
    if (this.searchItems.length <= 1) {
      const yeniKisiOption = {
        ...new KisiEntity(),
        ...new KurumEntity(),
        id: 0,
        ad: "Yeni Kişi",
        soyad: "",
        sirket_adi: "Yeni Kurum"
      };
      
      this.searchItems = [yeniKisiOption];
    }
  }
};
